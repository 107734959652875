import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./Projects.scss";

const Projects = ({ history }) => {
  const projects = useSelector((state) => state.data.projects);

  return (
    <>
      <div className="subheader-wrapper">
        <div className="projects-subheader">
          <h3 className="sub-title">
            <FormattedMessage id="navigation.projects" />
          </h3>
          <p className="sub-info">
            <FormattedMessage id="projects.title" />
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <div className="projects">
          <Row>
            {projects.map((item, index) => (
              <Col lg={6} key={index.toString()}>
                <div
                  className="project-card"
                  onClick={() =>
                    history.push(`${history.location.pathname}/${item.slug}`)
                  }
                >
                  <div className="img-wrapper">
                    <Image src={item.cover_image} className="card-img" />
                  </div>
                  <figcaption className="card-details">
                    <h4>{item.title}</h4>

                    <p>{item.location}</p>
                  </figcaption>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Projects;
