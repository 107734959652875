import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import BackGroundSlider from "../BackGroundSlider/BackGroundSlider";

const JoinUsPage = () => {
  const contactBg = useSelector((state) => state.data.bg.contact);
  return (
    <div className="contact">
      <BackGroundSlider data={contactBg} />
      <div className="slider-caption first-slider-caption">
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
            flex: 1,
          }}
        >
          <h5 className="mt-4" style={{ color: "#0c3179" }}>
            <FormattedMessage id="join.title" />
          </h5>
          <p>
            <FormattedMessage id="join.subtitle" />
          </p>
          <div>
            <span className="mr-2">
              <i className="far fa-envelope"></i>
            </span>
            <a href="mailto:info@goksuoglumetal.com">info@goksuoglumetal.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUsPage;
