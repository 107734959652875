import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./Services.scss";

const Services = ({ history }) => {
  const services = useSelector((state) => state.data.services);

  return (
    <>
      <div className="subheader-wrapper">
        <div className="services-subheader">
          <h3 className="sub-title">
            <FormattedMessage id="navigation.services" />
          </h3>
          <p className="sub-info">
            <FormattedMessage id="services.title" />
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <div className="services">
          <Row>
            {services.map((item, index) => (
              <Col lg={4} key={index.toString()}>
                <div
                  className="service-card"
                  onClick={() =>
                    history.push(`${history.location.pathname}/${item.slug}`)
                  }
                >
                  <div className="img-wrapper">
                    <Image
                      src={item.cover_image_secondary}
                      className="card-img"
                    />
                  </div>
                  <figcaption className="card-details">
                    <h4>{item.title}</h4>
                  </figcaption>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Services;
