import React from "react";
import { Image, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./ActivityPage.scss";

const ActivityPage = () => {
  const activities = useSelector((state) => state.data.activities);

  return (
    <>
      <div className="activity-wrapper">
        <div className="activity-subheader">
          <h3 className="sub-title">
            <FormattedMessage id="navigation.activities" />
          </h3>
          <p className="sub-info">
            <FormattedMessage id="activities.title" />
          </p>
        </div>
      </div>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <div className="activities">
          <Row>
            {activities.map((item, index) => (
              <Col lg={4} key={index.toString()}>
                <div className="activity-card">
                  <div className="img-wrapper">
                    <Image src={item.cover_image} className="card-img" />
                  </div>
                  <figcaption className="card-details">
                    <h4>{item.title}</h4>
                  </figcaption>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default ActivityPage;
