import React, { useEffect, useState } from "react";
import axios from "axios";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";

import { setData } from "../../redux/actions";
import {
  Header,
  HomePage,
  ProjectDetails,
  ProjectsPage,
  ContactPage,
  AboutPage,
  ReferencesPage,
  ServicesPage,
  ServiceDetails,
  ActivityPage,
  JoinUsPage,
  CertificatesPage,
} from "./";
import Logo from "../../assets/Logo";
import "./App.scss";

const App = () => {
  const data = useSelector((state) => state.data);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      axios.get(`/lang?locale=TR`).then((response) => {
        dispatch(setData(response.data));
        setLoading(false);
      });
    } else {
      axios
        .get(`/lang/?locale=${localStorage.getItem("lang")}`)
        .then((response) => {
          dispatch(setData(response.data));
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <IntlProvider locale={"EN"} messages={data.data}>
        {loading ? (
          <Logo className="spinner-position" />
        ) : (
          <>
            <Header languages={data.languages} />
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route
                path="/contact"
                component={() => <ContactPage languages={data.data} />}
              />
              <Route
                path="/about"
                component={() => (
                  <AboutPage aboutText={data.data["about.text"]} />
                )}
              />
              <Route path="/projects/:id" component={ProjectDetails} />
              <Route path="/projects" component={ProjectsPage} />
              <Route path="/activities" component={ActivityPage} />
              <Route path="/certificates" component={CertificatesPage} />
              <Route
                path="/references"
                component={() => (
                  <ReferencesPage references={data.references} />
                )}
              />
              <Route path="/services/:id" component={ServiceDetails} />
              <Route path="/services" component={ServicesPage} />
              <Route path="/join-us" component={JoinUsPage} />
              <Route path="/error" component={ErrorPage} />
              <Redirect to="/error" />
            </Switch>
          </>
        )}
      </IntlProvider>
    </div>
  );
};

export default App;

const ErrorPage = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "40%",
        fontSize: "3rem",
        fontWeight: 800,
      }}
    >
      404 Not Found
    </div>
  );
};
