import React, { useState, useEffect } from "react";
import { Nav, Navbar, Form, NavDropdown } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./Header.scss";
import Logo from "../../assets/Logo";

const Header = ({ languages }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  let history = useHistory();

  const navExpandedHandler = (expanded) => {
    setNavExpanded(expanded);
  };

  const closeNav = () => {
    setNavExpanded(false);
  };

  useEffect(() => {
    history.listen(() => {
      closeNav();
    });
  }, [history]);

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      variant="light"
      className="header"
      onToggle={navExpandedHandler}
      expanded={navExpanded}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <NavLink to="/">
        <Logo width={60} height={60} />
      </NavLink>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <NavDropdown
            title={<FormattedMessage id="navigation.corporate" />}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item as="div">
              <NavLink to="/about">
                <FormattedMessage id="navigation.about" />
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item as="div">
              <NavLink to="/certificates">
                <FormattedMessage id="navigation.certificates" />
              </NavLink>
            </NavDropdown.Item>
          </NavDropdown>
          <NavLink
            className="nav-link"
            to="/projects"
            activeClassName={"nav-link--active"}
          >
            <FormattedMessage id="navigation.projects" />
          </NavLink>
          <NavLink
            className="nav-link"
            to="/references"
            activeClassName={"nav-link--active"}
          >
            <FormattedMessage id="navigation.references" />
          </NavLink>
          <NavLink
            className="nav-link"
            to="/activities"
            activeClassName={"nav-link--active"}
          >
            <FormattedMessage id="navigation.activities" />
          </NavLink>
          <NavLink
            className="nav-link"
            to="/services"
            activeClassName={"nav-link--active"}
          >
            <FormattedMessage id="navigation.services" />
          </NavLink>
          <NavDropdown
            title={<FormattedMessage id="navigation.contact" />}
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item as="div">
              <NavLink to="/contact">
                <FormattedMessage id="navigation.subcontact" />
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item as="div">
              <NavLink to="/join-us">
                <FormattedMessage id="navigation.join" />
              </NavLink>
            </NavDropdown.Item>
          </NavDropdown>

          <div style={{ padding: "5px" }}>
            <Form.Control
              as="select"
              onChange={(e) => {
                localStorage.setItem("lang", e.target.value);
                window.location.reload();
              }}
              value={
                localStorage.getItem("lang") === null
                  ? "TR"
                  : localStorage.getItem("lang")
              }
            >
              {languages.map((item, index) => (
                <option value={item} key={index.toString()}>
                  {item}
                </option>
              ))}
            </Form.Control>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
