import React from "react";

import BackGroundSlider from "../BackGroundSlider/BackGroundSlider";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import "./HomePage.scss";

const HomePage = () => {
  const homeBg = useSelector((state) => state.data.bg.home);
  return (
    <div className="home">
      <BackGroundSlider data={homeBg} />
      <div className="slider-caption">
        <h2 className="caption-title">
          <FormattedMessage id="home.title" />
        </h2>
        <p>
          <FormattedMessage id="home.subtitle" />
        </p>
      </div>
    </div>
  );
};

export default HomePage;
