import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";

import "./CertificatesPage.scss";

const CertificatesPage = () => {
  const certificates = useSelector((state) => state.data.certificates);

  return (
    <div className="cetificates-container">
      <Row>
        {certificates.map((item, index) => (
          <Col lg={4} md={6} sm={12} key={index.toString()}>
            <a
              href={item}
              // eslint-disable-next-line
              target="_blank"
            >
              <Image src={item} className="certificate_img" />
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CertificatesPage;
