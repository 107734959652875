import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import BackGroundSlider from "../BackGroundSlider/BackGroundSlider";
import "./About.scss";

const About = ({ aboutText }) => {
  const aboutBg = useSelector((state) => state.data.bg.about);
  return (
    <div className="about">
      <BackGroundSlider data={aboutBg} />
      <div className="slider-caption">
        <h2 className="caption-title">
          <FormattedMessage id="navigation.about" />
        </h2>
        <p
          dangerouslySetInnerHTML={{
            __html: aboutText,
          }}
        ></p>
      </div>
    </div>
  );
};

export default About;
