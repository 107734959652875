import React from "react";
import { Image } from "react-bootstrap";
import Slider from "react-slick";

import "./BackGroundSlider.scss";

const BackGroundSlider = ({ data }) => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    touchMove: false,
    adaptiveHeight: true,
    pauseOnHover: false,
  };
  return (
    <div className="bg-wrapper">
      <Slider {...settings}>
        {data.map((img, index) => (
          <div className="img-wrapper" key={index.toString()}>
            <Image className="bg-img" src={img} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BackGroundSlider;
