import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Button, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import axios from "axios";

import "./Contact.scss";
import BackGroundSlider from "../BackGroundSlider/BackGroundSlider";

const Contacts = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const contactBg = useSelector((state) => state.data.bg.contact);
  const languages = useSelector((state) => state.data.data);

  return (
    <div className="contact">
      <BackGroundSlider data={contactBg} />
      <div className="slider-caption first-slider-caption">
        <h2 className="caption-title">
          <FormattedMessage id="contact.info.title" />
        </h2>
        <div
          style={{
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
            flex: 1,
          }}
        >
          <h5 className="mt-3">
            <FormattedMessage id="contact.info.name" />
          </h5>
          <p>
            <FormattedMessage id="contact.info.address" />
          </p>
          <p>
            <span className="mr-2">
              <i className="fas fa-phone"></i>
            </span>
            <a href={`tel:${languages["contact.info.phone"]}`}>
              <FormattedMessage id="contact.info.phone" />
            </a>
          </p>
          <p>
            <span className="mr-2">
              <i className="far fa-envelope"></i>
            </span>
            <a href={`mailto:${languages["contact.info.email"]}`}>
              <FormattedMessage id="contact.info.email" />
            </a>
          </p>
          <p>
            <span className="mr-2">
              <i className="fas fa-map-marker-alt"></i>
            </span>
            <a
              href={languages["contact.info.map.url"]}
              // eslint-disable-next-line
              target="_blank"
            >
              <FormattedMessage id="contact.info.map.directions" />
            </a>
          </p>
          <p>
            <span className="mr-2">
              <i className="fas fa-map-marker-alt"></i>
            </span>
            <a
              href={languages["contact.info.map.directions.factory.url"]}
              // eslint-disable-next-line
              target="_blank"
            >
              <FormattedMessage id="contact.info.map.directions.factory" />
            </a>
          </p>
          <p>
            <span className="mr-2">
              <i class="fas fa-download"></i>
            </span>
            <a
              href={languages["contact.download"]}
              // eslint-disable-next-line
              target="_blank"
            >
              <FormattedMessage id="contact.catalog" />
            </a>
          </p>
        </div>
      </div>
      <div className="slider-caption sec-slider-caption">
        <h2 className="caption-title">
          <FormattedMessage id="contact.form.heading" />
        </h2>
        <Formik
          initialValues={{
            email: "",
            name: "",
            title: "",
            description: "",
          }}
          onSubmit={(values) => {
            axios
              .get(
                `/contact?locale=${localStorage.getItem("lang")}&email=${
                  values.email
                }&name=${values.name}&title=${values.title}&description=${
                  values.description
                }`
              )
              .then((response) => {
                setIsSuccess(true);
                setSuccessMsg(response.data);
              })
              .catch((err) => {
                setIsError(true);
                setErrMsg(err.data);
              });
          }}
        >
          {({
            errors,
            touched,
            values,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="contact.form.email" />
                </Form.Label>
                <Form.Control
                  type="email"
                  required
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
              {errors.email && touched.email && (
                <Alert variant="danger">{errors.email}</Alert>
              )}
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="contact.form.name" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </Form.Group>
              {errors.name && touched.name && (
                <Alert variant="danger">{errors.name}</Alert>
              )}
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="contact.form.title" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  required
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows="3"
                />
              </Form.Group>
              {errors.title && touched.title && (
                <Alert variant="danger">{errors.title}</Alert>
              )}
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="contact.form.description" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  required
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows="3"
                />
              </Form.Group>
              {errors.description && touched.description && (
                <Alert variant="danger">{errors.description}</Alert>
              )}
              <Button type="submit">
                <FormattedMessage id="contact.form.send" />
              </Button>
              {isSuccess && (
                <Alert variant="success mt-2">{successMsg.message}</Alert>
              )}
              {isError && <Alert variant="success mt-2">{errMsg}</Alert>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Contacts;
