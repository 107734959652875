import React from "react";
import Slider from "react-slick";
import { Image, Modal } from "react-bootstrap";

const settings = {
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: false,
  arrows: true,
  draggable: false,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1919,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const BottomSlider = ({ data }) => {
  const [clickedImage, setClickedImage] = React.useState("");
  const [isClicked, setIsClicked] = React.useState(false);
  return (
    <div className="bottom-slider">
      <Modal
        show={isClicked}
        onHide={setIsClicked}
        className="slider-presentation"
        onClick={() => setIsClicked(false)}
      >
        <Image src={clickedImage} className="presentation-img" />
      </Modal>
      <Slider {...settings} arrows={true}>
        {data.length !== 0 &&
          data.map((image, index) => (
            <Image
              key={index.toString()}
              className="card-img "
              src={image}
              onClick={() => {
                setIsClicked(true);
                setClickedImage(image);
              }}
            />
          ))}
      </Slider>
    </div>
  );
};

export default BottomSlider;

function SampleNextArrow(props) {
  const { style, onClick } = props;
  let { className } = props;
  if (!className.includes("disabled")) {
    className = "";
  } else {
    className = "slick-disabled";
  }
  return (
    <i
      onClick={onClick}
      style={{ ...style }}
      // eslint-disable-next-line
      className={"fas fa-3x fa-chevron-circle-right" + " " + className}
    ></i>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  let { className } = props;
  if (!className.includes("disabled")) {
    className = "";
  } else {
    className = "slick-disabled";
  }
  return (
    <i
      onClick={onClick}
      style={{ ...style }}
      // eslint-disable-next-line
      className={"fas fa-3x fa-chevron-circle-left" + " " + className}
    ></i>
  );
}
