import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import Spinner from "../../assets/Spinner";
import axios from "axios";

const ServiceDetails = ({ match, history }) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getData = async () => {
    try {
      if (!localStorage.getItem("lang")) {
        const res = await axios.get(`/services/${match.params.id}?locale=TR`);

        setData(res.data);
        setLoading(false);
      } else {
        const res = await axios.get(
          `/services/${match.params.id}?locale=${localStorage.getItem("lang")}`
        );
        setData(res.data);
        setLoading(false);
      }
    } catch (err) {
      history.push("/error");
    }
  };

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const renderCols = (right) => {
    const { items } = data;
    let rightCol = [];
    let leftCol = [];

    items.forEach((item, index) => {
      if (index % 2 === 0) {
        leftCol.push(item);
      } else {
        rightCol.push(item);
      }
    });

    if (right) return rightCol;
    else return leftCol;
  };

  return (
    <>
      {loading ? (
        <Spinner className="spinner-position" />
      ) : (
        <div className="card-det-wrapper">
          <div className="lg-img-wrapper">
            <Image src={data.cover_image} className="lg-img" />
          </div>
          <div className="card-det-container">
            <div className="card-det-content-wrapper">
              <Row className="content-row">
                <Col sm={12} md={8} lg={7}>
                  <Row className="card-det-col-dir">
                    <Col lg={12}>
                      <div className="content-title">
                        <h2>{data.title}</h2>
                        <p style={{ fontWeight: 400 }}>{data.description}</p>
                      </div>
                    </Col>
                    <Col lg={12} md={12}>
                      <Row>
                        <Col lg={6} md={6}>
                          {renderCols(false).map((item, index) => (
                            <div
                              className="content-line"
                              key={index.toString()}
                            >
                              <h4>{item.name}</h4>
                              <p>{item.value} </p>
                            </div>
                          ))}
                        </Col>
                        <Col lg={6} md={6}>
                          {renderCols(true).map((item, index) => (
                            <div
                              className="content-line"
                              key={index.toString()}
                            >
                              <h4>{item.name}</h4>
                              <p>{item.value} </p>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col md={0} lg={1}></Col>
                <Col md={4} lg={4}>
                  <Image
                    src={data.images[0]}
                    className="card-det-container-img"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceDetails;
