import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import "./References.scss";

const ReferencesPage = ({ references }) => {
  return (
    <div className="ref-container">
      <Row>
        {references.map((item, index) => (
          <Col lg={2} md={3} sm={12} key={index.toString()}>
            <Image src={item} className="reference_img" />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ReferencesPage;
